import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
//import SEO from "../components/seo"

export const pageQuery = graphql`
  query AboutQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
				corporateName
				location
				tell
				ceo
				birth
				businessContent
				ceoProfile
      }
    }
  }
`

const AboutPage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
	const { frontmatter, html, excerpt } = markdownRemark
	const toHTML = (str) => { return str.replace(/\n/gm, '<br />')}
	return (
		<Layout className="page">
			<table ellpadding="0" cellspacing="1" class="_tbl" width="100%">
				<tr>
					<th width="100">商号:</th>
					<td>{frontmatter.corporateName}</td>
				</tr>
				<tr>
					<th width="100">所在:</th>
					<td dangerouslySetInnerHTML={{ __html: toHTML(frontmatter.location) }}></td>
				</tr>
				<tr>
					<th width="100">電話:</th>
					<td>{frontmatter.tell}</td>
				</tr>
				<tr>
					<th width="100">設立:</th>
					<td>{frontmatter.birth}</td>
				</tr>
				<tr>
					<th width="100">事業:</th>
					<td dangerouslySetInnerHTML={{ __html: toHTML(frontmatter.businessContent) }}></td>
				</tr>
				<tr>
					<th>代表:</th>
					<td 
					  className="ceo"
					  dangerouslySetInnerHTML={{ __html: html }} />
				</tr>
			</table>
			
			{/*
			<SEO
				title={frontmatter.title}
				description={excerpt}
			/>
			<div className="wrapper">
				<h1>{frontmatter.title}</h1>
				<article dangerouslySetInnerHTML={{ __html: html }} />
			</div>
	*/}

			<iframe 
				className="googleMap"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.127936002242!2d139.71805111525447!3d35.62457244068469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ae4e8a21d05%3A0x2c623e5099d34d89!2z44CSMTQxLTAwMzEgVG9reW8sIFNoaW5hZ2F3YSBDaXR5LCBOaXNoaWdvdGFuZGEsIDItY2jFjW1l4oiSMzDiiJI1IOacneaXpeilv-S6lOWPjeeUsOODnuODs-OCt-ODp-ODsw!5e0!3m2!1sen!2sjp!4v1623763141833!5m2!1sen!2sjp" 
				height="450"
				allowfullscreen=""
				loading="lazy" />

		</Layout>
	)
}

export default AboutPage